const colors = {
  primaryPurple: "#b10fda",
  black: "#000",
  black2: "#0c0c0c",
  white: "#fff",
  blackdarkmode: "#141414",
  darkmainnavy60: "#363c90",
  darkmainnavy50: "#121a8a",
  darkmainnavy40: "#0e156e",
  darkmainnavy20: "#070a37",
  darkmainnavy80: "#787ba6",
  darkmainnavy70: "#454a88",
  darkmainblue10: "#304762",
  darkmainblue20: "#3e5f8f",
  darkmainnavy30: "#0b1053",
  darkmainblue30: "#3e69ae",
  darkmainblue40: "#3f79cc",
  darkmainblue50: "#569cf2",
  darkmainnavy10: "#04051c",
  darkmainblue60: "#65aaf5",
  darkmainblue80: "#9dcefa",
  darkmainpink10: "#33011b",
  darkmainblue70: "#7bbaf7",
  darkmainpink30: "#990350",
  darkmainpink20: "#660235",
  darkmainpink40: "#cc046b",
  darkmainpink60: "#ff3fa1",
  darkmainpink80: "#ff9ace",
  darkmainpink50: "#ff0585",
  darkmainpink70: "#ff73bb",
  darksubcobaltblue: "#2558cf",
  darksublavenderblue: "#8dbaf8",
  darksubcoral: "#ed3137",
  darksubpictonblue: "#34c5ff",
  darksubgreen: "#07b07e",
  darksubbisque: "#ffd29c",
  darkgrey10: "#272727",
  darkgrey20: "#323232",
  darkgrey30: "#505050",
  darkgrey50: "#8a8a8a",
  darkgrey40: "#636363",
  darkgrey60: "#ababab",
  darkgrey70: "#d0d0d0",
  darkgrey80: "#e2e2e2",
  darkopacity20: "rgba(255, 255, 255, 0.08)",
  darkopacity10: "rgba(255, 255, 255, 0.06)",
  darkopacity30: "rgba(255, 255, 255, 0.1)",
  darkopacity40: "rgba(255, 255, 255, 0.12)",
  darkopacity50: "rgba(255, 255, 255, 0.14)",
  darkopacity60: "rgba(255, 255, 255, 0.16)",
  lightmainnavy10: "#e5e6f1",
  darkopacity70: "rgba(255, 255, 255, 0.18)",
  darkopacity80: "rgba(255, 255, 255, 0.2)",
  lightmainnavy20: "#9397c6",
  lightmainnavy30: "#4c52a0",
  lightmainnavy40: "#272e87",
  lightmainnavy70: "#00043c",
  lightmainnavy60: "#00054b",
  lightmainnavy50: "#040a5c",
  lightmainnavy80: "#00032c",
  lightmainblue10: "#e6f3ff",
  lightmainblue20: "#c3e0ff",
  lightmainblue40: "#7bb9ff",
  lightmainblue50: "#65aaff",
  lightmainblue30: "#9ecdff",
  lightmainblue60: "#589cfd",
  lightmainblue70: "#548dee",
  lightmainblue80: "#4f7bda",
  lightmainpink20: "#ffaed8",
  lightmainpink30: "#ff87c5",
  lightmainpink10: "#ffc7e4",
  lightmainpink40: "#ff59ae",
  lightmainpink50: "#ff319a",
  lightmainpink60: "#dd0775",
  lightmainpink70: "#c70066",
  lightsubcobaltblue: "#264a9f",
  lightmainpink80: "#a90056",
  lightsublavenderblue: "#b9d6ff",
  lightsubpictonblue: "#4ab4de",
  lightsubgreen: "#00c88c",
  lightsubcoral: "#ff3737",
  lightsubbisque: "#ffe4c4",
  lightgrey10: "#f2f2f2",
  lightgrey20: "#eaeaea",
  lightgrey30: "#d4d4d4",
  lightgrey40: "#aaa",
  lightgrey50: "#7d7d7d",
  lightgrey60: "#626262",
  lightgrey70: "#505050",
  lightgrey80: "#303030",
  lightopacity20: "rgba(0, 0, 0, 0.08)",
  lightopacity10: "rgba(0, 0, 0, 0.06)",
  lightopacity40: "rgba(0, 0, 0, 0.12)",
  lightopacity30: "rgba(0, 0, 0, 0.1)",
  lightopacity50: "rgba(0, 0, 0, 0.14)",
  lightopacity60: "rgba(0, 0, 0, 0.16)",
  lightopacity70: "rgba(0, 0, 0, 0.18)",
  lightopacity80: "rgba(0, 0, 0, 0.2)",
  darksuborange: "#ffa723",
  lightsuborange: "#ff8d23",
  amway : "#002f5f"
};



export { colors };
