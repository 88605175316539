import { useState, useEffect } from "react";
import { autoNumber } from "../../utils/validate";
import { gengerDatas } from "../../datas/tableDatas";
import { handleGetDateyyyymmdd } from "../../utils/commons";
import { useNavigate, useLocation } from "react-router-dom";
import { restfetcher, QueryKeys } from "../../queryClient";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../modules";
import { useQuery } from "react-query";

import * as S from "./LinkApp.styled";

//component
import AlertPopup from "../../components/popups/AlertPopup";
import InputPopup from "../../components/popups/InputPopup";
import LinkDataPopup1 from "../../components/popups/LinkDataPopup1";
import LinkDataPopup2 from "../../components/popups/LinkDataPopup2";

type PropsType = {
  handleAllClose: () => void;
  linkData: any;
};
const LinkApp = ({ handleAllClose, linkData }: PropsType) => {
  const [popupState, setState] = useState("AlertPopup");
  const [token, setToken] = useState<string>("");
  const [id, setId] = useState<string>("");
  const [childrenList, setChildrenList] = useState<any[] | null>(null);
  const [ga, setGA] = useState<string>("");


  const handleAlertY = () => {
    setState("InputPopup");
  };

  const handleInputY = (response: any, id: string) => {
    const { token, childrenList } = response;
    setToken(token);
    setChildrenList(childrenList);
    setId(id);
    setState("LinkDataPopup1");
  };

  const handleLink1Y = (ga: string) => {
    setGA(ga);
    setState("LinkDataPopup2");
  };

  const handleLink2Y = () => {
    setState("AlertPopup");
    handleAllClose();
  };

  console.log("popupState", popupState);
  return (
    <S.LinkApp>
      {popupState === "AlertPopup" && (
        <AlertPopup
          onClickY={handleAlertY}
          onClickN={handleAllClose}
          onClose={handleAllClose}
        />
      )}
      {popupState === "InputPopup" && (
        <InputPopup
          onClickY={handleInputY}
          onClickN={() => setState("AlertPopup")}
          onClose={handleAllClose}
          linkData={linkData}
        />
      )}
      {popupState === "LinkDataPopup1" && (
        <LinkDataPopup1
          onClickY={handleLink1Y}
          onClickN={() => setState("InputPopup")}
          onClose={handleAllClose}
          linkData={linkData}
          id={id}
        />
      )}
      {popupState === "LinkDataPopup2" && (
        <LinkDataPopup2
          onClickY={handleLink2Y}
          onClickN={() => setState("InputPopup")}
          onClose={handleAllClose}
          data={{
            appId: id,
            token: token,
            gaIdx: ga,
            integrationIdx: linkData?.integrationIdx,
            createdAt : linkData?.createdAt
          }}
          childrenList={childrenList}
        />
      )}
    </S.LinkApp>
  );
};

export default LinkApp;
