import styled from "styled-components";
import { font } from "../../assets/style/fonts";
import { colors } from "../../assets/style/colors";
import { LineTextInput } from "../inputs/lineTextInput/LineTextInput.Styles";


export const Popup = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    position:fixed;
    top:50%;
    left:50%;
    transform : translate(-50%, -50%);
    z-index:50;
    max-width:468px;
`;

export const PopupBox = styled.div`
    padding:32px 36px;
    position:relative;

`;

export const CloseBtn = styled.p`
    width:24px;
    height:24px;
    position:absolute;
    top:21px;
    right:21px;
    cursor:pointer;
    svg{
        width:100%;
        height:100%;
    }
`;

export const Title = styled.p`
    color: ${colors.lightgrey80};
    ${font.h3_medium};
    text-align:center;
`;


export const AlertPopup = styled.div`

`;

export const Alert = styled.p`
    ${font.body_regular};
    text-align:center;
    padding:24px 0 16px;
`;

export const Btns = styled.p`
    display:flex;
    gap:12px;
    justify-content:center;
    button{
        height:32px;
        width:140px;
    }
`;

//InputPopup
interface InputProps {
    isFocus?: boolean
}

export const InputPopup = styled.div`
`;


export const Input = styled.p<InputProps>`
    border:1px solid ${(props: InputProps) => { return props.isFocus ? colors.amway : `rgba(0, 0, 0, 0.1)` }};
    border-radius: 8px;
    padding:10px 8px;
    width:250px;
    margin:16px auto;
    background-color:rgba(0,0,0,0.1);
    input{
        width:100%;
        ${font.body_regular}
    }
`;


//Link1
export const LinkDataPopup1 = styled.div`
width: 360px;

`;

export const LinkDataTable = styled.div`
    margin:0 auto;
`;

export const Link1Thead = styled.ul`
    display:flex;
    background-color:rgba(0,0,0,0.1);
    border:1px solid rgba(0,0,0,0.4);
    height:30px;
`;

export const Link1Li = styled.li`
    border-left:1px solid rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-child{
        border:none;
    }
`;

export const LinkData = styled.li`
        display:block;
        overflow-y:auto;
        border-left:1px solid rgba(0,0,0,0.4);
        padding:8px;
        width:calc(100% - 120px);
`;

export const Link1Tbody = styled.ul`
    display:flex;
    border:1px solid rgba(0,0,0,0.4);
    border-bottom:none;
    border-top:none;
`;

export const Link1Tbody2 = styled.ul`
    display:flex;
    border:1px solid rgba(0,0,0,0.4);
    ${LinkData}{
        height:100px;
    }
`;



export const Data = styled.p`
    margin:12px 0;
`;

export const Text = styled.span`
    padding: 0 4px;
`;

export const BackBtn = styled.div`
    margin-top:28px;
    position:relative;
    text-align:center;
    height:32px;
    display: flex;
    align-items: center;
    justify-content: center;
    button{
        height:32px;
        width:140px;
    }
    svg{
        position:absolute;
        top:6px;
        left:0;
        width:24px;
        height:24px;
        cursor:pointer;
    }
`;

//LinkDataPopup2
export const LinkDataPopup2 = styled.div`
    width:300px;
`;

export const Link2Agree = styled.div`
    margin:28px 0;
`;

export const AgreeBtn = styled.p`
    height:40px;
    margin-bottom:12px;
`;

export const ChileSelectArea = styled.div`
    margin-top:28px;
`;

export const SelectTitle = styled.p`
    ${font.h4_medium};
`;

export const ChildArea = styled.ul`
    height:100px;
    background-color:rgba(0,0,0,0.1);
    padding:10px;
    margin-top:12px;
    border-radius: 8px;
    overflow-y:auto;
`;

export const ChildList = styled.li`
    margin:10px 0;
`;  

export const AgreePopup = styled.div`
    position:fixed;
    top:50px;
    left:100px;
    z-index:1000;
`; 