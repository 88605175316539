import styled from "styled-components";
import { font } from "../../assets/style/fonts";
import { colors } from "../../assets/style/colors";
import { SquareBtn } from "../buttons/squareBtn/SquareBtn.Styles";

export const AdminTable = styled.div`
    background: #FFFFFF;
    margin-top:48px;
    min-height:841px;
`;

export const CountRea = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:0 8px 16px;
`;

export const Total = styled.p`
    ${font.caption1_medium};
`;

export const PageCount = styled.p`
    ${font.caption1_medium};
`;

export const AdminThead = styled.ul`
    border-bottom:1px solid rgba(0, 0, 0, 0.1);
    display:flex;
    ${font.caption1_medium};
    padding:16px 8px 16px;
    align-items:center;
    justify-content:space-around;
    gap:8px;
    background-color:rgba(0, 0, 0, 0.1);
`;

export const Th = styled.li`
    display:flex;
    align-items:center;
    justify-content:center;
    color : ${colors.black2};
`;

export const AdminTbody = styled.ul`

`;

export const List = styled.li`
    border-bottom:1px solid rgba(0, 0, 0, 0.1);
    padding:16px 8px;
    display:flex;
    align-items:center;
    justify-content:space-around;
    gap:8px;
`;

export const Data = styled.p`
    display:flex;
    align-items:center;
    justify-content:center;
    ${font.caption1_regular};
    ${SquareBtn}{
        ${font.caption1_regular};
        height:24px;
    }
    svg{
        height:24px;
    }
`;


export const BottomArea = styled.div`
    display:flex;
    justify-content:space-between;
    margin-top:32px;
    padding:0 16px;
    ${SquareBtn}{
        ${font.caption1_medium};
        height:32px;
        width:130px;
    }
`;

export const Page = styled.div`

`;

export const Nodata = styled.li`
    text-align:center;
    margin-top:200px;
`;