import styled from "styled-components";
import { font } from "../../assets/style/fonts";
import { colors } from "../../assets/style/colors";
import { SquareBtn } from "../../components/buttons/squareBtn/SquareBtn.Styles";

export const Home = styled.div`
    display:flex;
`;

export const Section = styled.section`
    flex:1;
    padding:32px;
    margin-left:264px;
`;

export const Header = styled.header`
    color:${colors.lightgrey80};
    display:flex;
    align-items:center;
    justify-content:flex-end;
    ${font.body_medium};
    margin-top:12px;
    margin-bottom:48px;
    ${SquareBtn}{
        width:100px;
        margin-left:24px;
        ${font.body_medium};
        height:30px;
    }
`;

export const LineIcon = styled.i`
    display:block;
    height:10px;
    width:1px;
    background-color:${colors.lightgrey40};
    margin:0 12px;
`;

