import React, { useEffect, useLayoutEffect, useState } from "react";
import * as S from "./Pagination.styled";
import { searchGet, handleSearchUrlAdd } from "../../utils/commons";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as IconPrev } from "../../assets/icon/ic_chevron_left.svg";
import { ReactComponent as IconPrev2 } from "../../assets/icon/ic_chevron_left_2.svg";
import { ReactComponent as IconNext } from "../../assets/icon/ic_chevron_right.svg";
import { ReactComponent as IconNext2 } from "../../assets/icon/ic_chevron_right_2.svg";

type Propstype = {
  totalItems : number,
  itemsPerPage : number, 
}

const Pagination = ({ totalItems, itemsPerPage}: Propstype) => {
  const {search} = useLocation();
  const navigate = useNavigate();
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(()=>{
    if(search){
      const page = Number(searchGet("page"));
      if(page !== currentPage){
        setCurrentPage(page);
      }
    }
  },[search]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    handlePage(page);
  };

  const handleFirst = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
      handlePage(1);
    }
  };

  const handleLast = () => {
    if (currentPage !== totalPages) {
      setCurrentPage(totalPages);
      handlePage(totalPages);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      handlePage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      handlePage(currentPage + 1);
    }
  };

  const handlePage = (page : number) => {
    const searchUrl = handleSearchUrlAdd("page", page, search);
    navigate(searchUrl);
  }

  const renderPageButtons = () => {
    const buttons = [];
    const maxButtons = 10;
    const startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
    const endPage = Math.min(totalPages, startPage + maxButtons - 1);

    for (let page = startPage; page <= endPage; page++) {
      buttons.push(
        <S.PageBtn
          key={page}
          onClick={() => handlePageChange(page)}
          disabled={currentPage === page}
        >
          {page}
        </S.PageBtn>
      );
    }

    return buttons;
  };

  return (
    <S.Pagination>
      <S.ChevronBtn disabled={currentPage === 1} onClick={handleFirst}>
        <IconPrev2 />
      </S.ChevronBtn>
      <S.ChevronBtn disabled={currentPage === 1} onClick={handlePrevious}>
        <IconPrev />
      </S.ChevronBtn>
      {renderPageButtons()}
      <S.ChevronBtn onClick={handleNext} disabled={currentPage === totalPages}>
        <IconNext />
      </S.ChevronBtn>
      <S.ChevronBtn disabled={currentPage === totalPages} onClick={handleLast}>
        <IconNext2 />
      </S.ChevronBtn>
    </S.Pagination>
  );
};

export default Pagination;
