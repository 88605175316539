import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { restfetcher, QueryKeys } from "./queryClient";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./modules";
import Router from "./Router";
//store
import { set_user_index, set_user_info, set_token } from "./modules/common";

function App() {
  // const dispatch = useDispatch();
  // let autoLogoutTimeout : any = null;
  // const { usridx, userInfo, token } = useSelector(
  //   (state: RootState) => state.common
  // );
  // const logoutApi = useQuery(QueryKeys.LOGOUT2, () =>
  //   restfetcher({
  //     method: "POST",
  //     path: "v1/auth/sign-out",
  //     params: {},
  //   })
  // );

  // useEffect(() => {
  //   console.log("logoutApi", logoutApi);
  //   const { isSuccess, data, remove } = logoutApi;
  //   if (isSuccess && data?.result === "success") {
  //     const userInfonew = {
  //       state: null,
  //       id: userInfo?.idSave ? userInfo.id : null,
  //       ip: null,
  //       idSave: userInfo?.idSave,
  //     };
  //     dispatch(set_token(null));
  //     dispatch(set_user_index(null));
  //     dispatch(set_user_info(userInfonew));
  //     window.location.href = "/";
  //     alert("세션 만료로 자동 로그아웃 되었습니다.");
  //     remove();
  //   }
  // }, [logoutApi.isSuccess, logoutApi.data]);

  // useEffect(() => {
  //   if (usridx && token) {
  //     console.log("app token :: ", token);
  //   if(autoLogoutTimeout) clearTimeout(autoLogoutTimeout)

  //     autoLogoutTimeout = setTimeout(() => {
  //       const { isSuccess, data, remove, refetch } = logoutApi;
  //       refetch();
  //       console.log("logoutApi", logoutApi);
  //     }, 1800000); //1800000
  //   }
  // }, [token]);



  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
