export const adminTable = [
    {
        key : "check",
        value : "선택",
        width : "28px"
    },
    {
        key : "no",
        value : "번호",
        width : "30px"
    },
    {
        key : "appid",
        value : "건강성장앱 ID",
        width : "84px"
    },
    {
        key : "aboNo",
        value : "ABO/멤버ID",
        width : "105px"
    },
    {
        key : "childrenName",
        value : "자녀이름(나이)",
        width : "90px"
    },
    {
        key : "childrenNickname",
        value : "닉네임",
        width : "40px"
    },
    {
        key : "gender",
        value : "자녀성별",
        width : "52px"
    },
    {
        key : "childrenBirthDay",
        value : "자녀 생년월일",
        width : "80px"
    },
    {
        key : "parentsPhone",
        value : "부모 휴대폰번호",
        width : "95px"
    },
    {
        key : "type",
        value : "구분",
        width : "30px"
    },
    {
        key : "createdAt", //inspectionDate
        value : "검사일자",
        width : "70px"
    },
    {
        key : "reportUrl",
        value : "리포트",
        width : "40px"
    },
    {
        key : "linkAPP",
        value : "APP연동하기",
        width : "80px"
    },
    {
        key : "sendDate",
        value : "전송완료",
        width : "90px"
    }
];

export const link1Table = [
    {
        key : "division",
        value : "구분",
        width : "100px"
    },
    {
        key : "data",
        value : "측정 데이터",
        width : "242px"
    }
]


export const gengerDatas = [
    {
        key : "N",
        value : "모두"
    },
    {
        key : "M",
        value : "남자"
    },
    {
        key : "F",
        value : "여자"
    }
]


