import * as S from "./SquareBtn.Styles";

type Props = {
  text: string;
  onClick: () => void;
  action?: boolean;
  colorType?: string
};

const SquareBtn = ({ text, onClick, action = true, colorType }: Props) => {
  return (
    <S.SquareBtn onClick={onClick} action={action} colorType={colorType}>
      {text}
    </S.SquareBtn>
  );
};

export default SquareBtn;
