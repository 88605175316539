//숫자 정규식
export const autoNumber = (tell: string) => {
    return tell.replace(/[^0-9]/g, '')
    // .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
}

//한글 제외 
export const autoText = (text: string) => {
    const ko = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;
    const koTest = ko.test(text);
    console.log('koTest', koTest)
    if(koTest) alert("영문, 숫자만 입력하여 주세요.");
    return text.replace(ko, '')
    // .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
}

//전화번호 유효성검사
export const telValidator = (tell: string) => {
    if (/^[0-9]{9,11}/.test(tell) && tell.length < 12) {
        return true;
    }
    return false;
}


let checkNum = /[0-9]/;
let checkEn= /[a-zA-Z]/;
let checkSpc1 = /[~!@#$%^&*()_+|<>?:{}]/;
let checkSpc2 = /[~!@#$%^&*()+|<>?:{}]/;
let checkKor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

export const validateUserId = (id : string) => {
    if(id.length > 16 || id.length < 4 || checkKor.test(id) ||checkSpc2.test(id)){
        return false;
    }else{
        return true;
    }
}

export const validateRePw = (pw : string, repw : string) => {
    if(pw.length > 16 || pw.length < 4 || checkKor.test(pw) || pw !== repw){
        return false;
    }else{
        return true;
    }
}