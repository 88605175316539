const font = {
    h1: {
        fontFamily: "GmarketSansBold",
        fontSize: " 40px",
        fontWeight: " bold",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: " normal",
        letterSpacing: " -1px",
    },
    h2: {
        fontFamily: "GmarketSansBold",
        fontSize: " 24px",
        fontWeight: " bold",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: " normal",
        letterSpacing: " -1px",
    },

    d1: {
        fontFamily: "GmarketSansMedium",
        fontSize: " 50px",
        fontWeight: " bold",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: " normal",
        letterSpacing: " -1px",
    },

    d2: {
        fontFamily: "GmarketSansMedium",
        fontSize: " 40px",
        fontWeight: " bold",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: " normal",
        letterSpacing: " -1px",
    },

    d3: {
        fontFamily: "GmarketSansMedium",
        fontSize: " 24px",
        fontWeight: " bold",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: " normal",
        letterSpacing: " -1px",
    },
    d4: {
        fontFamily: "GmarketSansMedium",
        fontSize: " 18px",
        fontWeight: " bold",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: " normal",
        letterSpacing: " -1px",
    },
    gmarketd1: {
        fontFamily: "GmarketSansMedium",
        fontSize: " 50px",
        fontWeight: " bold",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: " normal",
        letterSpacing: " -1px",
    },
    d_menu: {
        fontFamily: " Fredoka One, cursive",
        fontSize: " 20px",
        fontWeight: " normal",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: " normal",
        letterSpacing: "-0.3px",
    },
    fredokaOned1: {
        fontFamily: " Fredoka One, cursive",
        fontSize: " 60px",
        fontWeight: " normal",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: " normal",
        letterSpacing: "-0.3px",
    },
    fredokaOneH1: {
        fontFamily: " Fredoka One, cursive",
        fontSize: " 48px",
        fontWeight: " normal",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: " normal",
        letterSpacing: "-0.3px",
    },

    d1_bold: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 44px",
        fontWeight: " bold",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.09",
        letterSpacing: "-0.3px",
    },

    d1_medium: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 44px",
        fontWeight: " 500",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.09",
        letterSpacing: "-0.3px",
    },
    d1_regular: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 44px",
        fontWeight: " normal",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.09",
        letterSpacing: "-0.3px",
    },
    d1_light: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 44px",
        fontWeight: " 300",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.09",
        letterSpacing: "-0.3px",
    },
    h1_bold: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 30px",
        fontWeight: " bold",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.2",
        letterSpacing: "-0.3px",
    },
    h1_medium: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 30px",
        fontWeight: " 500",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.2",
        letterSpacing: "-0.3px",
    },
    h1_regular: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 30px",
        fontWeight: " normal",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.2",
        letterSpacing: "-0.3px",
    },
    h1_light: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 30px",
        fontWeight: " 300",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.2",
        letterSpacing: "-0.3px",
    },
    h2_bold: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 24px",
        fontWeight: " bold",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.25",
        letterSpacing: "-0.3px",
    },
    h2_medium: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 24px",
        fontWeight: " 500",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.25",
        letterSpacing: "-0.3px",
    },
    h2_regular: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 24px",
        fontWeight: " normal",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.25",
        letterSpacing: "-0.3px",
    },
    h2_light: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 24px",
        fontWeight: " 300",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.25",
        letterSpacing: "-0.3px",
    },
    h3_blod: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 20px",
        fontWeight: " bold",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.2",
        letterSpacing: "-0.3px",
    },
    h3_medium: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 20px",
        fontWeight: " 500",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.2",
        letterSpacing: "-0.3px",
    },
    h3_Regular: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 20px",
        fontWeight: " normal",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.2",
        letterSpacing: "-0.3px",
    },
    h3_light: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 20px",
        fontWeight: " 300",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.2",
        letterSpacing: "-0.3px",
    },
    h4_bold: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 18px",
        fontWeight: " bold",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.22",
        letterSpacing: "-0.3px",
    },
    h4_medium: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 18px",
        fontWeight: " 500",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.22",
        letterSpacing: "-0.3px",
    },
    h4_regular: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 18px",
        fontWeight: " normal",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.22",
        letterSpacing: "-0.3px",
    },
    h4_light: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 18px",
        fontWeight: " 300",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.22",
        letterSpacing: "-0.3px",
    },
    body_medium: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 16px",
        fontWeight: " 500",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.25",
        letterSpacing: "-0.3px",
    },
    body_regular: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 16px",
        fontWeight: " normal",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.25",
        letterSpacing: "-0.3px",
    },
    body_light: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 16px",
        fontWeight: " 300",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.25",
        letterSpacing: "-0.3px",
    },
    caption1_medium: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 14px",
        fontWeight: " 500",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: " 1.29",
        letterSpacing: "-0.3px",
    },
    caption1_regular: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 14px",
        fontWeight: " normal",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: " 1.29",
        letterSpacing: "-0.3px",
    },
    caption1_light: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 14px",
        fontWeight: " 300",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: " 1.29",
        letterSpacing: "-0.3px",
    },
    caption2_medium: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 13px",
        fontWeight: " 500",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.33",
        letterSpacing: "-0.3px",
    },
    caption2_regular: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 13px",
        fontWeight: " normal",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.33",
        letterSpacing: "-0.3px",
    },
    caption2_light: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 13px",
        fontWeight: " 300",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.33",
        letterSpacing: "-0.3px",
    },
    caption3_bold: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 12px",
        fontWeight: " bold",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.33",
        letterSpacing: "-0.3px",
    },
    caption3_medium: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 12px",
        fontWeight: " 500",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.33",
        letterSpacing: "-0.3px",
    },
    caption3_regular: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 12px",
        fontWeight: " normal",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.33",
        letterSpacing: "-0.3px",
    },
    caption3_light: {
        fontFamily: " Noto Sans KR, sansSerif",
        fontSize: " 12px",
        fontWeight: " 300",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "1.33",
        letterSpacing: "-0.3px",
    },

    // global



}

export { font };