import { useState, useEffect } from "react";
import { autoNumber } from "../../utils/validate";
import { gengerDatas } from "../../datas/tableDatas";
import { handleGetDateyyyymmdd } from "../../utils/commons";
import { useNavigate, useLocation } from "react-router-dom";
import { restfetcher, QueryKeys } from "../../queryClient";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../modules";
import { useQuery } from "react-query";

//style
import * as S from "./AdminSearch.styled";

import { handleSearchUrlAdd, searchGet } from "../../utils/commons";

//component
import LineTextInput from "../inputs/lineTextInput/LineTextInput";
import SquareBtn from "../buttons/squareBtn/SquareBtn";
import AdminTable from "../adminTable/AdminTable";
import RadioInput from "../inputs/radioInput/RadioInput";

const AdminSearch = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [list, setList] = useState<any[] | null>(null);
  const defaultData = {
    appid: "",
    abo: "",
    childrenName: "",
    gender: "N",
    childrenBirthDay: "",
    parentsPhone: "",
    inspectionStartDate: "",
    inspectionEndDate: "",
  };
  const [searchState, setSearchState] = useState({
    appid: "",
    abo: "",
    childrenName: "",
    gender: "N",
    childrenBirthDay: "",
    parentsPhone: "",
    inspectionStartDate: "",
    inspectionEndDate: "",
  });
  
  const [page, setPage] = useState<number>(1);
  // const [submitState, setSubmitState] = useState({
  //   appid: "",
  //   abo: "",
  //   childrenName: "",
  //   gender: "N",
  //   childrenBirthDay: "",
  //   parentsPhone: "",
  //   inspectionStartDate: "",
  //   inspectionEndDate: "",
  // });
  const [searchCall, setSearchCall] = useState<string | null>(null);
  // const page = searchGet("page") || 1;

  const integrationApi = useQuery(QueryKeys.INTEGRATION, () =>
    restfetcher({
      method: "GET",
      path: `v1/integration${searchCall}`,
    })
  );

  useEffect(() => {
    const { isSuccess, data, remove } = integrationApi;
    console.log("integrationApi", integrationApi);
    if (isSuccess && data?.result === "success") {
      const { list } = data?.response;
      setList(list);
      setSearchCall(null);
    }
  }, [integrationApi.isSuccess, integrationApi.data]);

  useEffect(() => {
    if (searchCall) {
      integrationApi.refetch();
    }
  }, [searchCall]);

  useEffect(() => {
    const urlState = {
      appid: searchGet("appid") || "",
      abo: searchGet("abo") || "",
      childrenName: searchGet("childrenName") || "",
      gender: searchGet("gender") || "N",
      childrenBirthDay: searchGet("childrenBirthDay") || "",
      parentsPhone: searchGet("parentsPhone") || "",
      inspectionStartDate: searchGet("inspectionStartDate") || "",
      inspectionEndDate: searchGet("inspectionEndDate") || "",
    };

    console.log('JSON.stringify(urlState) == JSON.stringify(defaultData)', JSON.stringify(urlState) === JSON.stringify(defaultData))
    if (JSON.stringify(urlState) !== JSON.stringify(defaultData)) {
      setSearchState(urlState);
      setSearchCall(search);
    }else{
      setSearchState(defaultData);
      setSearchCall(null);
      setList(null);
    }
  }, [search]);

  const handleChangeSearch = (e: any) => {
    let { name, value } = e.target;
    if (
      name === "parentsPhone" ||
      name === "childrenBirthDay" ||
      name === "inspectionStartDate" ||
      name === "inspectionEndDate"
    ) {
      if (
        (name === "childrenBirthDay" ||
          name === "inspectionStartDate" ||
          name === "inspectionEndDate") &&
        value.length > 8
      ) {
        return false;
      }
      value = autoNumber(value);
    }
    setSearchState({ ...searchState, [name]: value });
  };

  const handleBlurDateCheck = () => {
    const today = handleGetDateyyyymmdd(new Date());
    const { inspectionStartDate, inspectionEndDate } = searchState;
    if (inspectionStartDate !== "" && inspectionEndDate !== "") {
      if (inspectionEndDate > today) {
        return alert("과거의 데이터만 조회 가능합니다.");
      }

      if (inspectionStartDate > inspectionEndDate) {
        return alert("시작일을 확인하여 주세요.");
      }
    }
  };

  const handleSearchSubmit = () => {
    integrationApi.remove();
    handleBlurDateCheck();
    let searchUrl = "";
    const page = searchGet("page");

    if(JSON.stringify(searchState) !== JSON.stringify(defaultData)){
      for (let [key, value] of Object.entries(searchState)) {
        searchUrl = handleSearchUrlAdd(key, value, searchUrl);
      }
      if(Number(page) !== 1) searchUrl = handleSearchUrlAdd("page", 1, searchUrl);
    }
     if(searchUrl !== search){
      navigate(searchUrl);
     }
  };

  return (
    <S.AdminSearch>
      <S.SearchArea>
        <S.SearchBox>
          <S.Search>
            <S.Label>건강성장앱 ID</S.Label>
            <LineTextInput
              value={searchState.appid}
              onChange={handleChangeSearch}
              onKeyDown={() => {}}
              name="appid"
            />
          </S.Search>
        </S.SearchBox>
        <S.SearchBox>
          <S.Search>
            <S.Label>ABO/멤버 ID</S.Label>
            <LineTextInput
              value={searchState.abo}
              onChange={handleChangeSearch}
              onKeyDown={() => {}}
              name="abo"
            />
          </S.Search>
        </S.SearchBox>
        <S.SearchBox>
          <S.Search>
            <S.Label>자녀이름</S.Label>
            <LineTextInput
              value={searchState.childrenName}
              onChange={handleChangeSearch}
              onKeyDown={() => {}}
              name="childrenName"
            />
          </S.Search>
        </S.SearchBox>
        <S.SearchBox>
          <S.Search>
            <S.Label>자녀성별</S.Label>
            <S.GangerArea>
              {gengerDatas.map((data) => (
                <RadioInput
                  key={data.key}
                  name={"gender"}
                  text={data.value}
                  checked={searchState.gender === data.key}
                  onChange={() => {
                    setSearchState({ ...searchState, gender: data.key });
                  }}
                  value={data.value}
                />
              ))}
            </S.GangerArea>
          </S.Search>
        </S.SearchBox>
        <S.SearchBox>
          <S.Search>
            <S.Label>자녀 생년월일</S.Label>
            <LineTextInput
              value={searchState.childrenBirthDay}
              onChange={handleChangeSearch}
              onKeyDown={() => {}}
              name="childrenBirthDay"
            />
          </S.Search>
          <S.Placeholder>{`* 8자리 숫자로 입력 예) 20230101`}</S.Placeholder>
        </S.SearchBox>
        <S.SearchBox>
          <S.Search>
            <S.Label>부모 휴대폰번호</S.Label>
            <LineTextInput
              value={searchState.parentsPhone}
              onChange={handleChangeSearch}
              onKeyDown={() => {}}
              name="parentsPhone"
            />
          </S.Search>
          <S.Placeholder>{`* 하이픈(-) 제외 숫자만 입력`}</S.Placeholder>
        </S.SearchBox>
        <S.DateArea>
          <S.Label>검사일자</S.Label>
          <LineTextInput
            value={searchState.inspectionStartDate}
            onChange={handleChangeSearch}
            onKeyDown={() => {}}
            name="inspectionStartDate"
          />
          ~
          <LineTextInput
            value={searchState.inspectionEndDate}
            onChange={handleChangeSearch}
            onKeyDown={() => {}}
            name="inspectionEndDate"
            handleBlur={handleBlurDateCheck}
          />
          <S.Placeholder>{`* 8자리 숫자로 입력 예) 20230101`}</S.Placeholder>
        </S.DateArea>
      </S.SearchArea>
      <S.Btns>
        <SquareBtn
          text="초기화"
          onClick={() => {
            navigate("/home");
          }}
          colorType="gray"
        />
        <SquareBtn text="검색" onClick={handleSearchSubmit} />
      </S.Btns>
      <AdminTable
        datas={list}
        totalElements={integrationApi?.data?.response?.totalElements}
        totalPage={integrationApi?.data?.response?.totalPage}
      />
    </S.AdminSearch>
  );
};

export default AdminSearch;
