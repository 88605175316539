import * as S from "./SideBar.styled";

//component
import SquareBtn from "../buttons/squareBtn/SquareBtn";

const SideBar = () => {
  return (
    <S.SideBar>
      <S.TopArea>
        <S.Logo></S.Logo>
        <S.Title>
          건강성장 플래너
          <br /> 데이터 연동 관리자
        </S.Title>
      </S.TopArea>
      {/* <S.BottomArea>
        <SquareBtn text="개인정보 이용동의" onClick={() => {}} colorType="gray"/>
      </S.BottomArea> */}
    </S.SideBar>
  );
};

export default SideBar;
