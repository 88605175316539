import { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { restfetcher, QueryKeys } from "../../queryClient";
import { useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL, REPORT_URL } from "../../datas/common";
import { RootState } from "../../modules";
import * as S from "./Report.styled";
import { set_token, set_user_index } from "../../modules/common";

const Report = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.common);
  const { integrationIdx } = useParams();
  const imageRef: any = useRef();

  useEffect(() => {
    if (token) init();
  }, [token]);

  async function init() {
    try {
      const res: any = await fetch(
        `${REPORT_URL}v1/integration/${integrationIdx}/pdf-report`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.ok) {

        const blob = await res.blob();
        let objectURL = URL.createObjectURL(blob);
        console.log(imageRef.current);
        imageRef.current.src = objectURL;

      } else if (res.status === 401) {
        const fetchOptionsToken: RequestInit = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: "{}",
        };
        const refreshedToken = await fetch(
          `${BASE_URL}v1/auth/token`,
          fetchOptionsToken
        ); // 토큰 갱신 함수 호출 (사용자 정의)
        console.log("refreshedToken", refreshedToken);
        const refreshedTokenJson = await refreshedToken.json();
        const { jwt, userIdx } = refreshedTokenJson.response;
        dispatch(set_token(jwt));
        dispatch(set_user_index(userIdx));
      }else if(res.status === 403) {
        dispatch(set_token(null));
        dispatch(set_user_index(null));
        alert("세션 만료로 자동 로그아웃 되었습니다.");
        window.close();
      }
    } catch (error) {
      console.error("Failed to fetch PDF blob:", error);
    }
  }

  return (
    <S.Report>
      <embed
        id="my-audio"
        ref={imageRef}
        type="application/pdf"
        width="100%"
        height={window.innerHeight}
      />
    </S.Report>
  );
};

export default Report;
