import styled from "styled-components";
import { font } from "../../assets/style/fonts";
import { colors } from "../../assets/style/colors";


interface Props {
    disabled : boolean
}

export const Pagination = styled.div`

`;

export const ChevronBtn = styled.button<Props>`
    min-width: 32px;
    height: 32px;
    padding: 0 6px;
    margin: 0 3px;
    svg{
        ${(props)=> !props.disabled ? `fill :${colors.black};` : `fill :${colors.lightgrey40};`}
    }
`;

export const PageBtn = styled.button<Props>`
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    text-align: center;
    box-sizing: border-box;
    min-width: 32px;
    height: 32px;
    padding: 0 6px;
    margin: 0 3px;
    cursor:pointer;
    ${(props)=> props.disabled && `color :${colors.black}; background-color:  rgba(0,0,0,0.1);`}
`;  