//선택한 데이터 있으면 빼고, 없으면 넣고
export const handleSelectDatas = (select: any, datas: any) => {
  let newAddTagArr: any = [];
  const selectArr = datas;
  console.log(
    "selectArr?.findIndex(select)",
    selectArr?.findIndex((find: any) => find === select)
  );
  if (
    datas &&
    datas.length > 0 &&
    selectArr?.findIndex((find: any) => find === select) > -1
  ) {
    newAddTagArr = selectArr.filter(
      (element: any) => JSON.stringify(element) !== JSON.stringify(select)
    );
  } else {
    newAddTagArr = [...datas, select];
  }
  return newAddTagArr;
};

export const handleGetDateyyyymmdd = (date: string | Date) => {
  const today = new Date(date);
  const newToday = today.toISOString().split("-");

  const ymd = `${newToday[0]}${newToday[1]}${newToday[2]}`;
  return ymd.slice(0, 8);
};

//원하는 검색 값 url에 셋팅
export const handleSearchUrlAdd = (
  key: string,
  value: number | string,
  searchUrl: string | null
) => {
  const pathname = window.location.pathname;
  let search: string | string[] = searchUrl
    ? searchUrl?.split("&")
    : window.location.search?.split("&"); //url에 search값 가져오기
  let newSearch = value === "" ? "" : `${pathname}?${key}=${value}`; //page값을 제외한 search값 문자열 담을 변수

  if (search && search[0] !== "" && search.length > 0) {
    //search값이 있는지 판단하기
    //search에 page값이 아닌 다른 search keyword를 string으로 연결하기
    const isLargeNumber = (element: any) => element.indexOf(`${key}=`) > -1;
    let index = search.findIndex(isLargeNumber);
    if (search[index]) {
      const splitSearch = search[index].split("=");
      splitSearch[1] = value.toString();
      search[index] = splitSearch.join("=");
      newSearch = search.join("&");
    } else {
      newSearch = search.join("&") + `&${key}=${value}`;
    }
  }
  //newSearch값이 ""이면 pathname에 "?"를 붙혀서 셋팅 아닐경우 "&"으로 붙히기
  const url = newSearch;

  return url;
};

//url에서 원하는 search값 가져오기
export const searchGet = (cate: string) => {
  const urlSearch = window.location.search; //url에 search값 가져오기
  if (urlSearch && urlSearch !== "") {
    //search값이 있는지 판단하기
    const search = urlSearch?.split("&"); //search값에 "&"로 제외하여 배열로 만들기
    const getCateArr = search?.filter((text: any) => text.indexOf(cate) > -1); //배열 중에 원하는(cate)값이 포함된 배열 가져오기
    const getCate = getCateArr[0]?.split(`${cate}=`)[1]; //배열에서 cate포함된 = 문자열 잘라서 순수 search값 추출하기
    return getCate && decodeURI(getCate);
  }
};
