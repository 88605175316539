import { useState } from "react";

//style
import * as S from "./Popups.styled";

//icon
import {ReactComponent as IconClose} from "../../assets/icon/ic_x.svg";

//component
import SquareBtn from "../buttons/squareBtn/SquareBtn";

type Propstype = {
  title?: string;
  onClickY : ()=>void;
  onClickN : ()=>void;
  onClose: ()=>void;
};
const AlertPopup = ({ title = "APP 연동하기", onClickY, onClickN, onClose}: Propstype) => {
  return (
    <S.Popup>
      <S.PopupBox>
        <S.CloseBtn><IconClose onClick={onClose}/></S.CloseBtn>
        <S.Title>{title}</S.Title>
        <S.AlertPopup>
            <S.Alert>
                자녀 데이터를 APP에 연동하시겠습니까?
            </S.Alert>
            <S.Btns>
                <SquareBtn text="예" onClick={onClickY}/>
                <SquareBtn text="아니오" onClick={onClickN} colorType="gray"/>
            </S.Btns>
        </S.AlertPopup>
      </S.PopupBox>
    </S.Popup>
  );
};

export default AlertPopup;
