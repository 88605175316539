import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { restfetcher, QueryKeys } from "../../queryClient";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../modules";

import * as S from "./Home.styled";

//store
import { set_user_index, set_user_info, set_token } from "../../modules/common";


//component
import SideBar from "../../components/sideBar/SideBar";
import SquareBtn from "../../components/buttons/squareBtn/SquareBtn";
import AdminList from "../../components/adminSearch/AdminSearch";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {userInfo} = useSelector((state : RootState)=> state.common);

  const logoutApi = useQuery(QueryKeys.LOGOUT, () =>
    restfetcher({
      method: "POST",
      path: "v1/auth/sign-out",
      params: {},
    })
  );

  useEffect(() => {
    const { isSuccess, data, remove } = logoutApi;
    if (isSuccess && data?.result === "success") {
      const userInfonew = {
        state: null,
        id: userInfo?.idSave ? userInfo.id : null,
        ip:  null,
        idSave: userInfo?.idSave,
      };
      dispatch(set_token(null));
      dispatch(set_user_index(null));
      dispatch(set_user_info(userInfonew));
      navigate("/");
      remove();
    }
  }, [logoutApi.isSuccess, logoutApi.data]);

  const onClickLogout = () => {
    if (window.confirm("로그아웃 하시겠습니까?")) {
      logoutApi.refetch();
    }
  };

  return (
    <S.Home>
      <SideBar />
      <S.Section>
        <S.Header>
          ID : {userInfo?.id}
          <S.LineIcon />
          IP : {userInfo?.ip}
          <SquareBtn text="로그아웃" onClick={onClickLogout} colorType="gray" />
        </S.Header>
        <AdminList />
      </S.Section>
    </S.Home>
  );
};

export default Home;
