import { useState, useEffect, useLayoutEffect } from "react";
import { restfetcher, QueryKeys } from "../../queryClient";
import { useQuery } from "react-query";

import * as S from "./Popups.styled";

//icon
import { ReactComponent as IconClose } from "../../assets/icon/ic_x.svg";
import { ReactComponent as IconBack } from "../../assets/icon/ic_arrow_left.svg";

import { link1Table } from "../../datas/tableDatas";

//component
import SquareBtn from "../buttons/squareBtn/SquareBtn";
import RadioInput from "../inputs/radioInput/RadioInput";

type Propstype = {
    title?: string;
    onClickY : (gaList : string )=>void;
    onClickN : ()=>void;
    onClose: ()=>void;
    linkData : any,
    id : string
  };

const LinkDataPopup1 =  ({ title = "APP 연동하기", onClickY, onClickN, onClose, linkData, id}: Propstype) => {
  const [selectPA, setSelectPA] = useState<string>("");
  const [selectGA, setSelectGA] = useState<string>("");
  const [palist, setPAList] = useState<any[] | null>(null);
  const [galist, setGAList] = useState<any[] | null>(null);


  const mathApi = useQuery(QueryKeys.PAGALIST, () =>
  restfetcher({
    method: "GET",
    path: `v1/integration/${linkData?.integrationIdx}/match?id=${id}`,
  })
);


useLayoutEffect(()=>{
  mathApi.refetch();
},[])

useEffect(()=>{
  const {isSuccess, data, remove} =mathApi;
  if (isSuccess && data?.result === "success") {
    const {paList, gaList} = data.response;
    setPAList(paList);
    setSelectPA(paList[0].paIdx);
    setGAList(gaList);
    remove();
  }
},[mathApi.isSuccess , mathApi.data])

  const onClickSubmit = () => {
    onClickY(selectGA);
    // console.log('selectPA', selectPA)
    // if(selectPA === ""){
    //   return alert("PA 데이터를 선택해 주세요.");
    // }else{
      
    // }
  }


  return (
    <S.Popup>
      <S.PopupBox>
      <S.CloseBtn><IconClose onClick={onClose}/></S.CloseBtn>
        <S.Title>{title}</S.Title>
          <S.LinkDataPopup1>
          <S.Alert>연동하실 데이터를 선택해 주세요.</S.Alert>
          <S.LinkDataTable>
            <S.Link1Thead>
              {link1Table.map((data) => (
                <S.Link1Li
                  key={`link1_${data.key}`}
                  style={{ width: data.width }}
                >
                  {data.value}
                </S.Link1Li>
              ))}
            </S.Link1Thead>
            <S.Link1Tbody>
              <S.Link1Li style={{ width: "100px" }}>PA*</S.Link1Li>
              <S.LinkData>
                {palist && palist?.map((data, index)=>(
                    <S.Data>
                      <S.Text>{data.createdAt}</S.Text>
                        {/* <RadioInput
                               name=""
                               text={data.createdAt}
                               checked={selectPA === data.paIdx}
                               onChange={()=>{setSelectPA(data.paIdx)}}
                               value={""}
                        /> */}
                    </S.Data>
                ))}
              </S.LinkData>
            </S.Link1Tbody>
            <S.Link1Tbody2>
              <S.Link1Li style={{ width: "100px" }}>GA</S.Link1Li>
              <S.LinkData>
                {galist && galist.length > 0 ? galist?.map((data, index)=>(
                    <S.Data>
                        <RadioInput
                               name=""
                               text={data.createdAt}
                               checked={selectGA === data.gaIdx}
                               onChange={()=>{setSelectGA(data.gaIdx)}}
                               value={""}
                        />
                    </S.Data>
                ))
              :   <S.Data><S.Text>측정 데이터 없음</S.Text></S.Data>
              }
              </S.LinkData>
            </S.Link1Tbody2>
          </S.LinkDataTable>
        </S.LinkDataPopup1>
        <S.BackBtn>
            <IconBack onClick={onClickN}/>
            <SquareBtn text="확인" onClick={onClickSubmit}/>
        </S.BackBtn>
      </S.PopupBox>
    </S.Popup>
  );
};

export default LinkDataPopup1;
