import styled from "styled-components";
import { font } from "../../assets/style/fonts";
import { colors } from "../../assets/style/colors";
import { Input, LineTextInput } from "../inputs/lineTextInput/LineTextInput.Styles";

export const AdminSearch = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.1);
    max-width:1400px;
    margin:0 auto 100px;
    padding:72px;
`;

export const SearchArea = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color:#fff;
    border-radius: 8px;
    gap:24px 64px;
    padding:0 16px;
    ${LineTextInput}{
        background-color:${colors.lightgrey20};
        border-radius: 8px;
        border:none;
        padding:0 16px;
        input{width:100%;};
        border:1px solid ${(props : any)=>{ return props.isFocus ? colors.amway : `rgba(0, 0, 0, 0)`}};
        height: 42px;

    }
`;

export const SearchBox = styled.div`

`;

export const Search = styled.div`
    display:flex;
    gap:10px;
    align-items:center;
    ${Input}{
        text-align:left;
    }
`;

export const Label = styled.p`
    width:140px;
    min-width:140px;
    line-height:49px;
`;

export const Placeholder = styled.p`
    ${font.caption2_light};
    color:${colors.lightgrey70};
    text-indent:150px;
    margin-top:4px;
`;

export const DateArea = styled.div`
    display:flex;
    gap:10px;
    align-items:center;
    grid-column-start: 1;
	grid-column-end: 3;
    ${LineTextInput}{
        width:230px;
        ${Input}{
            text-align:left;
        }
    }
    ${Placeholder}{
        text-indent:8px;
    }
`;

export const Btns = styled.p`
    display:flex;
    height:36px;
    gap:16px;
    justify-content:flex-end;
    border-bottom:1px solid rgba(0, 0, 0, 0.1);
    padding:48px 16px 72px;
    button{
        width:140px;
        ${font.body_medium};
    }
`;

export const GangerArea = styled.div`
    display:flex;
    gap:20px;
    align-items:center;
`;  