import styled from "styled-components";

export const LinkApp = styled.div`
    background-color:#333;
    position:fixed;
    top:0;
    right:0;
    z-index:10;
    height:100vh;
    width:100%;
`;