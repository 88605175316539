import styled from "styled-components";
import { font } from "../../assets/style/fonts";
import { colors } from "../../assets/style/colors";
import { SquareBtn } from "../buttons/squareBtn/SquareBtn.Styles";

export const SideBar = styled.div`
    background-color:${colors.amway};
    width:200px;
    height:calc(100vh - 64px);
    padding:32px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    position:fixed;
    top:0;
    left:0;
    z-index:100;
`;

export const TopArea = styled.div`

`;

export const Logo = styled.h1`
    background:url(${"https://media.amway.co.kr/sys-master/images/h1a/h94/9306828013598/topHeaderLogoV2Image.png"}) no-repeat center;
    background-size:100%;
    width:70%;
    height:40px;
    padding-top:32px;
`;  

export const Title = styled.h2`
    ${font.h4_light};
    color:${colors.white};
    line-height:24px;
    margin-top:10px;
`;

export const BottomArea = styled.div`
    ${SquareBtn}{
        height:42px;
    }
`;