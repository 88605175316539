import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { restfetcher, QueryKeys } from "../../queryClient";
import { useSelector } from "react-redux";
import { RootState } from "../../modules";
import parser from "html-react-parser";

import * as S from "./Popups.styled";

//icon
import { ReactComponent as IconClose } from "../../assets/icon/ic_x.svg";
import { ReactComponent as IconBack } from "../../assets/icon/ic_arrow_left.svg";

//component
import SquareBtn from "../buttons/squareBtn/SquareBtn";
import RadioInput from "../inputs/radioInput/RadioInput";
import CheckInput from "../inputs/checkInput/CheckInput";

type Propstype = {
  title?: string;
  onClickY: () => void;
  onClickN: () => void;
  onClose: () => void;
  data: {
    appId: string;
    token: string;
    gaIdx: string;
    integrationIdx: string;
    createdAt: string;
  };
  childrenList: any[] | null;
};

const LinkDataPopup2 = ({
  title = "APP 연동하기",
  onClickY,
  onClickN,
  onClose,
  data,
  childrenList,
}: Propstype) => {
  const { token } = useSelector((state: RootState) => state.common);
  const [agree, setAgree] = useState(false);
  const [selectChild, setSelectChild] = useState<any | null>(null);
  const [html, setHtml] = useState<string | null>(null);

  const transmitApi = useQuery(QueryKeys.TRANSMIT, () =>
    restfetcher({
      method: "POST",
      path: `v1/integration/${data.integrationIdx}/transmit`,
      params: {
        appId: data.appId,
        uid: selectChild?.uid,
        token: data.token,
        gaIdx: data.gaIdx,
        childrenNickName: selectChild?.childrenNickName,
      },
    })
  );

  useEffect(() => {
    return () => {
      transmitApi.remove();
    };
  }, []);

  useEffect(() => {
    const { isSuccess, data, remove } = transmitApi;
    console.log("data ?? ", data);
    if (isSuccess) {
      if (data?.result === "success") {
        window.location.reload();
        alert("검사정보 데이터 연동이 완료 되었습니다.");
        onClickY();
        remove();
      } else {
        alert(data?.errmsg);
      }
    }
  }, [transmitApi.isSuccess, transmitApi.data]);

  const onClickSubmit = () => {
    if (!agree) {
      return alert("데이터전송동의를 진행해주세요.");
    }

    if (selectChild === null) {
      return alert("자녀 프로필을 선택해주세요.");
    }

    if (window.confirm("선택한 자녀 프로필을 APP데이터로 연동하시겠습니까?")) {
      transmitApi.refetch();
    }
  };

  const onClickAgree = () => {
    if (selectChild === null) {
      return alert("자녀 프로필을 선택해주세요.");
    }
    window.open(
      "https://ums.amway.co.kr/m/sry/indexP.ums?srvySeq=5",
      "",
      "width=780, height=600"
    );
    //${BASE_URL}v1/integration/${data.integrationIdx}/view?uid=${selectChild?.uid}&createdAt=${data.createdAt}
  };

  return (
    <S.Popup>
      <S.PopupBox>
        <S.CloseBtn>
          <IconClose onClick={onClose} />
        </S.CloseBtn>
        <S.Title>{title}</S.Title>
        <S.LinkDataPopup2>
          <S.ChileSelectArea>
            <S.SelectTitle>자녀 프로필 선택</S.SelectTitle>
            <S.ChildArea>
              {childrenList?.map((data, index) => (
                <S.ChildList>
                  <RadioInput
                    name=""
                    text={data.childrenNickName}
                    checked={selectChild?.uid === data.uid}
                    onChange={() => {
                      setSelectChild(data);
                    }}
                    value={""}
                  />
                </S.ChildList>
              ))}
            </S.ChildArea>
          </S.ChileSelectArea>
          <S.Link2Agree>
            <S.AgreeBtn>
              <SquareBtn
                text="데이터 전송동의"
                onClick={onClickAgree}
                colorType="gray"
              />
            </S.AgreeBtn>
            <CheckInput
              text="데이터전송동의를 완료하였습니다."
              checked={agree}
              onChange={() => setAgree(!agree)}
            />
          </S.Link2Agree>
        </S.LinkDataPopup2>
        <S.BackBtn>
          <IconBack onClick={onClickN} />
          <SquareBtn
            text={transmitApi.isLoading ? "연동 중..." : "확인"}
            onClick={onClickSubmit}
            action={!transmitApi.isLoading}
          />
        </S.BackBtn>
      </S.PopupBox>
    </S.Popup>
  );
};

export default LinkDataPopup2;
