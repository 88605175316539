import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import Home from './view/home/Home';
import Login from "./views/login/Login";
import Home from "./views/home/Home";
import Report from "./views/report/Report";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/home" element={<Home />}></Route>
        <Route path="/report/:integrationIdx" element={<Report />}></Route>
        {/* {routerData.map((data) => (
          <Route
            key={data.path}
            path={data.path}
            element={data.element}
          ></Route>
        ))} */}
        {/* <Route path="/" element={<Home />}></Route>
                    <Route path="/login" element={<Login />}></Route>
					<Route path="/inspectionEquipment" element={<InspectionEquipment />}></Route> */}
        {/* <Route path="/product/*" element={<Product />}></Route> */}
        {/* 상단에 위치하는 라우트들의 규칙을 모두 확인, 일치하는 라우트가 없는경우 처리 */}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
