import { useState, useEffect } from "react";
import { restfetcher, QueryKeys } from "../../queryClient";
import { useQuery } from "react-query";

import { autoText } from "../../utils/validate";
//style
import * as S from "./Popups.styled";

//icon
import { ReactComponent as IconClose } from "../../assets/icon/ic_x.svg";

//component
import SquareBtn from "../buttons/squareBtn/SquareBtn";
import LineTextInput from "../inputs/lineTextInput/LineTextInput";

type Propstype = {
  title?: string;
  onClickY : (response : any, id:string)=>void;
  onClickN : ()=>void;
  onClose: ()=>void;
  linkData : any
};
const InputPopup = ({title = "APP 연동하기", onClickY, onClickN, onClose, linkData } : Propstype) => {
  const [isFocus, setIsFocus] = useState(false);
  const [id, setId] = useState("");

  const integrationApi = useQuery(QueryKeys.TOKENCHILDREN, () =>
    restfetcher({
      method: "GET",
      path: `v1/integration/${linkData?.integrationIdx}/children?id=${id}`,
    })
  );


  useEffect(()=>{
    const {isSuccess, data, remove} =integrationApi;
    if (isSuccess) {
      if(data?.result === "success"){
        onClickY(integrationApi?.data?.response, id)
      }else{
        alert("일치하는 ID가 없습니다.")
      }
      remove();
    }
  },[integrationApi.isSuccess , integrationApi.data])

  return (
    <S.Popup>
      <S.PopupBox>
        <S.CloseBtn>
          <IconClose  onClick={onClose}/>
        </S.CloseBtn>
        <S.Title>{title}</S.Title>
        <S.InputPopup>
          <S.Alert>
            건강성장 플래너 ID 입력
            <S.Input isFocus={isFocus}>
              <input
                type="text"
                onFocus={() => {
                  setIsFocus(true);
                }}
                onBlur={() => {
                  setIsFocus(false);
                }}
                value={id}
                onChange={(e : any) => {setId(autoText(e.target.value))}}
                name={"id"}
                placeholder="ID 입력해 주세요."
              />
            </S.Input>
          </S.Alert>
          <S.Btns>
            <SquareBtn text="예" onClick={()=>integrationApi.refetch()} />
            <SquareBtn text="아니오" onClick={onClickN} colorType="gray" />
          </S.Btns>
        </S.InputPopup>
      </S.PopupBox>
    </S.Popup>
  );
};

export default InputPopup;
