import { useState, useEffect } from "react"

import * as S from "./LineTextInput.Styles"


type Props = {
    lable ?: string,
    value: string,
    onChange: (e:any)=>void,
    type?:string,
    onKeyDown?:(e:any)=>void,
    name :string,
    handleBlur ?: ()=>void
}

const LineTextInput = ({
    lable,
    value,
    onChange,
    type,
    onKeyDown,
    name,
    handleBlur
} : Props) => {
    const [isFocus, setIsFocus] = useState(false);

    const onBlur = () => {
        handleBlur && handleBlur();
        setIsFocus(false);
    }

    return (
        <S.LineTextInput isFocus={isFocus}>
            {lable && <S.Text>{lable}</S.Text>}
            <S.Input 
                placeholder="입력하세요"
                value={value}
                onChange={onChange}
                onFocus={()=>{setIsFocus(true)}}
                onBlur={onBlur}
                type={type}
                onKeyDown={onKeyDown}
                name={name}
            />
        </S.LineTextInput>
    )
}

export default LineTextInput;