import styled from "styled-components";
import { font } from "../../../assets/style/fonts";
import { colors } from "../../../assets/style/colors";

interface OptionInfoProps {

}

export const RadioInput = styled.p`
    position: relative;
    display:flex;
    align-items: center;
`;

export const Input = styled.input`
    width:20px;
    height:20px;
    min-width: 20px;
    min-height: 20px;
    opacity: 0;
    z-index: 2;
    position:absolute;
    cursor: pointer;
    & + label{
      display:block;
      width:16px;
      height:16px;
      min-width:16px;
      min-height:16px;
      border-radius: 50%;
      background: ${colors.white};
      border: 2px solid rgba(0, 0, 0, 0.2);
      position:absolute;
      top:0;
      left:0;
    }
    &:checked + label:after{
      display:block;
      width:11px;
      height:11px;
      min-width:11px;
      min-height:11px;
      content:'';
      border-radius: 50%;
      background-color: ${colors.lightmainnavy50};
      position:absolute;
      top:50%;
      left:50%;
      transform: translate(-50%, -50%);
    }
`;

export const Text = styled.span`
  margin-left:28px;
  ${font.body_regular};
  color: ${colors.lightgrey80};
`;