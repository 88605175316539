import styled from "styled-components";
import { font } from "../../../assets/style/fonts";
import { colors } from "../../../assets/style/colors";

interface SquareBtnProps {
    action: boolean;
    colorType?: string;
}

export const SquareBtn = styled.button<SquareBtnProps>`
    padding:0 13px;
    border-radius: 4px;
    ${font.h4_medium};
    display:flex;
    align-items:center;
    justify-content : center;
    cursor: pointer;
    background-color:${colors.white};
    background-image: none;
    color:${colors.lightgrey50};
    height:100%;
    outline:none;
    border:none;
    width:100%;
    ${(props) => (props.action && `   
    color: ${colors.white};   
    background-image: linear-gradient(to bottom, ${colors.amway} 0%, ${colors.lightmainnavy50} 100%);}
    background-color:rgba(0,0,0,0);
    opacity: 1;
    `
    )}
    ${(props) => (props?.colorType === "gray" && `   
    background-color:${colors.white};
    background-image: none;
    border:1px solid ${colors.amway};
    color:${colors.amway};
    `
    )}
    &:hover{
        opacity: 0.8; 
    }
`;