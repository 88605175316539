import { QueryClient } from "react-query";
import { store } from "./modules";
import { set_token, set_user_index, set_user_info } from "./modules/common";
import { BASE_URL } from "./datas/common";

type AnyOBJ = { [key: string]: any };
let timeOut: any = null;

export const getClient = (() => {
    let client: QueryClient | null = null;
    return () => {
        if (!client) client = new QueryClient({
            defaultOptions: {
                queries: {
                    staleTime: 0,
                    cacheTime: Infinity,
                    refetchOnMount: false,
                    refetchOnReconnect: false,
                    refetchOnWindowFocus: false,
                    enabled: false,
                }
            }
        });
        return client;
    }
})();



type fetchType = {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    path: string;
    params?: AnyOBJ
}
export const restfetcher = async ({
    method,
    path,
    params
}: fetchType) => {
    try {

        const token = store.getState().common.token;
        let url = `${BASE_URL}${path}`;
        const fetchOptions: RequestInit = {
            method,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        if (token) {
            fetchOptions.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        if (params) fetchOptions.body = JSON.stringify(params);
        const res: any = await fetch(url, fetchOptions)
        console.log(url, fetchOptions, res);

        if (timeOut) {
            clearTimeout(timeOut);
            timeOut = null;
        }
        timeOut = setTimeout(() => {
            logout();
        }, 60000 * 30)

        if (res.ok) {
            const json = await res?.json() || await res.text();
            console.log(url, params, json);

            return json;
        } else {

            const error : any = new Error(`HTTP Error `);

            console.log("res.status", res.status);
            if (res.status === 401) {
                const fetchOptionsToken: RequestInit = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: "{}"
                }
                const refreshedToken = await fetch(`${BASE_URL}v1/auth/token`, fetchOptionsToken); // 토큰 갱신 함수 호출 (사용자 정의)
                console.log('refreshedToken',`${BASE_URL}v1/auth/token`,  refreshedToken)
                const refreshedTokenJson = await refreshedToken.json();
                console.log('refreshedToken', refreshedTokenJson?.response)
                if(refreshedTokenJson?.response){
                    const { jwt, userIdx } = refreshedTokenJson?.response;
                    console.log('jwt', jwt)
                    if (refreshedToken && jwt) {
                        store.dispatch(set_token(jwt));
                        store.dispatch(set_user_index(userIdx));
                        fetchOptions.headers = {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${jwt}`
                        }
                        const refreshedResponse = await fetch(url, fetchOptions);
                        const refreshedResult = await refreshedResponse.json();
                        // setData(refreshedResult);
                        return refreshedResult;
                    } else {
                        console.log("status  and jwt", refreshedTokenJson.status, jwt);
                        if (refreshedTokenJson.status === 403 || !jwt) {
                            store.dispatch(set_token(null));
                            store.dispatch(set_user_index(null));
                            window.location.pathname = "/";
                            alert("토큰 갱신에 실패하였습니다. 세션 만료로 자동 로그아웃 되었습니다.");
                        }
                    }
                }else{
                    store.dispatch(set_token(null));
                    store.dispatch(set_user_index(null));
                    window.location.pathname = "/";
                    alert("세션 만료로 자동 로그아웃 되었습니다.");
                }
            } else{ //if (res.status === 403) 
                store.dispatch(set_token(null));
                store.dispatch(set_user_index(null));
                window.location.pathname = "/";
                alert("세션 만료로 자동 로그아웃 되었습니다.");
            }
            throw new error;
        }

    } catch (error: any) {
        console.error(error);
        store.dispatch(set_token(null));
        store.dispatch(set_user_index(null));
        
        const param = { logger : {
            url : `${BASE_URL}${path}`,
            param : params,
            name : error.name,
            message: error.message,
            stack : error.stack,
        }}

        console.log('param', param);
        const fetchOptions: RequestInit = {
            method,
            headers: {
                'Content-Type': 'application/json'
            },
            body  : JSON.stringify(param)
        }

        await fetch(`${BASE_URL}v1/web/log`, fetchOptions);
    }
}


const logout = async () => {
    const token = store.getState().common.token;
    const fetchOptions: RequestInit = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: "{}"
    }
    console.log(`${BASE_URL}v1/auth/sign-out`, fetchOptions);
    const logoutRes = await fetch(`${BASE_URL}v1/auth/sign-out`, fetchOptions);
    console.log(`${BASE_URL}v1/auth/sign-out`, logoutRes);
    if (logoutRes.ok) {
        store.dispatch(set_token(null));
        store.dispatch(set_user_index(null));
        window.location.pathname = "/";
        alert("세션 만료로 자동 로그아웃 되었습니다.");
    }
}

export const QueryKeys = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    LOGOUT2: "LOGOUT2",
    INTEGRATION: "INTEGRATION",
    INTEGRATIONDELETE: "INTEGRATIONDELETE",
    REPORT: "REPORT",
    TOKENCHILDREN: "TOKENCHILDREN",
    PAGALIST: "PAGALIST",
    TRANSMIT: "TRANSMIT",
    INTEGTAIONVIEW: ""
}
