import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { restfetcher, QueryKeys } from "../../queryClient";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../modules";

import { adminTable, gengerDatas } from "../../datas/tableDatas";
import { handleSelectDatas, searchGet } from "../../utils/commons";

import * as S from "./AdminTable.styled";

//icon
import { ReactComponent as IconReport } from "../../assets/icon/ic_page_lined.svg";

//components
import SquareBtn from "../buttons/squareBtn/SquareBtn";
import CheckInput from "../inputs/checkInput/CheckInput";
import PaginationButtons from "../pagination/Pagination";
import LinkApp from "../../views/linkApp/LinkApp";

type Propa = {
  datas?: any[] | null;
  totalElements: number;
  totalPage: number;
};

const AdminTable = ({ datas, totalElements, totalPage }: Propa) => {
  const { search } = useLocation();
  const {userInfo} = useSelector((state: RootState)=>state.common);
  const [selects, setSelects] = useState<number[]>([]);
  const [linkData, setLickData] = useState<any | null>(null);

  const integrationdeleteApi = useQuery(QueryKeys.INTEGRATIONDELETE, () =>
    restfetcher({
      method: "POST",
      path: "v1/integration/delete",
      params: {
        idxList: selects,
      },
    })
  );

  useEffect(() => {
    const { isSuccess, data, remove } = integrationdeleteApi;
    console.log("data", data);
    if (isSuccess && data?.result === "success") {
      window.location.reload();
      remove();
    }
  }, [integrationdeleteApi.isSuccess, integrationdeleteApi.data]);

  const handleSelect = (index: number) => {
    const selectArray = handleSelectDatas(index, selects);
    setSelects(selectArray);
  };

  const handleAllClose = () => {
    setLickData(null);
  };

  const handleGender = (g: string) => {
    const gender = gengerDatas.filter((gender: any) => gender.key === g);
    return gender[0]?.value;
  };

  const hanedleDelete = () => {
    if(userInfo?.roles?.includes("ADMIN")){
      if (window.confirm("해당 데이터를 삭제하시겠습니까?")) {
        integrationdeleteApi.refetch();
      }
    }else{
      alert("관리자 계정만 삭제 가능합니다.")
    }
  };

  return (
    <S.AdminTable>
      {linkData && (
        <LinkApp handleAllClose={handleAllClose} linkData={linkData} />
      )}
      {datas && (
        <>
          {" "}
          <S.CountRea>
            <S.Total>{totalElements || 0}건</S.Total>
            <S.PageCount>
              {totalPage === 0 ? totalPage : searchGet("page") || 0}/{totalPage}{" "}
              page
            </S.PageCount>
          </S.CountRea>
          <S.AdminThead>
            {adminTable.map((data) => (
              <S.Th key={`th_${data.key}`} style={{ width: data.width }}>
                {data.value}
              </S.Th>
            ))}
          </S.AdminThead>
          <S.AdminTbody>
            {datas.length > 0 ? (
              datas?.map((user, index) => (
                <S.List key={`th_${index}`}>
                  {adminTable.map((data) => (
                    <S.Data style={{ width: data.width, minWidth: data.width }}>
                      {data.key === "check" ? (
                        <CheckInput
                          onChange={() => handleSelect(user?.integrationIdx)}
                          checked={
                            selects && selects?.includes(user?.integrationIdx)
                          }
                        />
                      ) : data.key === "reportUrl" ? (
                        // <SquareBtn text={data.value} onClick={() => {}} colorType="gray"/>
                        <IconReport
                          // onClick={() =>user.type === "GA" ? window.open(`/report/${user?.integrationIdx}`) : window.open(`${user.reportUrl}`)}
                          onClick={() =>
                            window.open(`/report/${user?.integrationIdx}`)
                          }
                        /> //
                      ) : data.key === "linkAPP" ? (
                        <>
                          {user.type === "GA" ? (
                            <></>
                          ) : (
                            <SquareBtn
                              text="APP"
                              onClick={() => {
                                setLickData(user);
                              }}
                            />
                          )}
                        </>
                      ) : data.key === "createdAt" ? (
                        user[data.key].replace("T", " ")
                      ) : (!user[data.key] || user[data.key] === "") &&
                        data.key !== "nickname" ? (
                        "N/A"
                      ) : data.key === "gender" ? (
                        handleGender(user.gender)
                      ) : (
                        user[data.key]
                      )}
                    </S.Data>
                  ))}
                </S.List>
              ))
            ) : (
              <S.Nodata>검색 정보가 없습니다.</S.Nodata>
            )}
          </S.AdminTbody>
        </>
      )}
      {datas && datas?.length > 0 && (
        <S.BottomArea>
          <SquareBtn
            text="선택 삭제"
            colorType="gray"
            onClick={hanedleDelete}
          />
          <S.Page>
            <PaginationButtons totalItems={totalElements} itemsPerPage={10} />
          </S.Page>
        </S.BottomArea>
      )}
    </S.AdminTable>
  );
};

export default AdminTable;
