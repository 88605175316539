import styled from "styled-components";
import { font } from "../../../assets/style/fonts"; 
import { colors } from "../../../assets/style/colors"; 

interface LineTextInputProps {
    isFocus: boolean;
}


export const LineTextInput = styled.div<LineTextInputProps>`
    border-bottom:1px solid ${(props :LineTextInputProps)=>{ return props.isFocus ? colors.amway : `rgba(0, 0, 0, 0.2)`}};
    width:100%;
    height:48px;
    align-items: center;
    justify-content: space-between;
    display:flex;
`

export const Text = styled.p`
    text-align: left;
    color: ${colors.lightgrey80};
    ${font.body_medium};
`;

export const Input = styled.input`
  text-align: right;
  color: ${colors.lightmainnavy50};
  ${font.body_regular};
  border:none;
  outline:none;
  background-color:rgba(0,0,0,0);
  &:placeholder{
    color: rgba(0, 0, 0, 0.4);
  }
`;