import styled from "styled-components";
import { font } from "../../assets/style/fonts";
import { colors } from "../../assets/style/colors";

import { LineTextInput } from "../../components/inputs/lineTextInput/LineTextInput.Styles";
import { SquareBtn } from "../../components/buttons/squareBtn/SquareBtn.Styles";
import { CheckInput } from "../../components/inputs/checkInput/CheckInput.styles";

export const Login = styled.div`
   // background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 30%);
    width:100%;
    height:100%;
`;

export const LoginConainer = styled.div`
    margin:0 auto;
    background-color:${colors.white};
    height:calc(100% - 165px);
    padding-top:15rem;
    ${SquareBtn}{
        height:48px;
    }
`;

export const Logo = styled.h1`
    width: 230px;
    margin:0 auto;
    display:block;
    position:relative;
    height:190px;
    img{
        width:100%;
    }
`;

export const Title = styled.p`
    ${font.h1_bold};
    font-size:28px;
    color: ${colors.lightgrey80};
    text-align: center;
`;

export const Text = styled.p`
    ${font.body_light};
    color: ${colors.lightgrey50};
    text-align: center;
    margin:60px 0 32px;
`;

export const InputArea = styled.div`
    margin:0 auto;
    width:320px;
   ${LineTextInput}{margin-bottom:20px;
        input{
            text-align:left;
        }
    } 
`;

export const AutoCheck = styled.div`
    margin-bottom:28px;
    ${CheckInput}{
        .text{
            ${font.body_light};
        }
    }
`;

export const InfoArea = styled.div`
    width:100%;
    position:absolute;
    bottom:0;
    border-top:1px solid ${colors.lightgrey30};
    background-color:#f8f8f8;
    padding:30px 0 56px;
    @media screen and (max-height: 1040px) {
        position:static;
        margin-top:100px;
    }
`;

export const desc = styled.p`
    ${font.caption1_medium};
    color:${colors.lightgrey80};
    text-align: center;
`;

export const ASTell = styled.p`
    ${font.body_medium};
    color:${colors.lightgrey80};
    text-align: center;
    margin:12px 0 8px;
`;

export const Tell = styled.p`
    ${font.body_medium};
    color:${colors.lightgrey80};
    text-align: center;
`;