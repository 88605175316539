import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { restfetcher, QueryKeys } from "../../queryClient";
import { useDispatch , useSelector} from "react-redux";
import { RootState } from "../../modules";

//store
import { set_user_index, set_user_info, set_token } from "../../modules/common";
import { autoText } from "../../utils/validate";
//style
import * as S from "./Login.Styles";

//component
import LineTextInput from "../../components/inputs/lineTextInput/LineTextInput";
import SquareBtn from "../../components/buttons/squareBtn/SquareBtn";
import CheckInput from "../../components/inputs/checkInput/CheckInput";

const Login = () => {
  const {userInfo} = useSelector((state : RootState )=> state.common);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [pw, setPW] = useState("");
  const [idSave, setIdSave] = useState(false);

  const loginApi = useQuery(QueryKeys.LOGIN, () =>
    restfetcher({
      method: "POST",
      path: "v1/auth/sign-in",
      params: {
        id: id,
        pw: pw,
      },
    })
  );


  useEffect(() => {
    const { isSuccess, data, remove } = loginApi;
    console.log('loginApi data ::', data);
    if (isSuccess) {
      if (data?.result === "success") {
        const { jwt, id, userIdx, ip, roles } = data.response;
        const userInfo = {
          state: jwt,
          ip: ip,
          id: id,
          idSave: idSave,
          roles : roles
        };
        dispatch(set_token(jwt));
        dispatch(set_user_index(userIdx));
        dispatch(set_user_info(userInfo));
        navigate("/home");
      } else {
        if(data?.errmsg){
          alert(data?.errmsg);
        }else{
          alert("로그인에 실패하였습니다. 다시 시도해 주세요.");
        }
      }
      remove();
    }
  }, [loginApi.isSuccess, loginApi.data]);

  useEffect(()=>{
    if(userInfo?.idSave){
      setId(userInfo?.id);
    }else{
      setId("");
    }
  },[userInfo])

  const handleChangeID = (e: any) => {
    const { name, value } = e.target;
    setId(autoText(value));
  };

  const handleChangePW = (e: any) => {
    const { name, value } = e.target;
    setPW(autoText(value));
  };

  const onClickLogin = () => {
    if (id === "" || pw === "") {
      return alert("아이디와 비밀번호를 입력하여주세요.");
    }
    loginApi.refetch();
  };



  return (
    <S.Login>
      <S.LoginConainer>
        <S.Logo>
          <img src={require("../../assets/icon/amway-default-logo.png")} />
        </S.Logo>
        <S.Title>건강성장 플래너 데이터 연동 관리자</S.Title>
        <S.Text>아이디와 비밀번호 입력 후 로그인을 해주세요.</S.Text>
        <S.InputArea>
          <LineTextInput
            lable="아이디"
            value={id}
            onChange={handleChangeID}
            name="id"
            //onKeyDown={onKeyDown}
          />
          <LineTextInput
            lable="비밀번호"
            value={pw}
            onChange={handleChangePW}
            type="password"
            name="pw"
            // onKeyDown={onKeyDown}
          />
          <S.AutoCheck>
            <CheckInput
              text="아이디 저장"
              checked={idSave}
              onChange={() => setIdSave(!idSave)}
            />
          </S.AutoCheck>
          <SquareBtn text="로그인" onClick={onClickLogin} />
        </S.InputArea>
        <S.InfoArea>
          <S.desc>
            * 관리자 계정이 잠겼을 경우 아래 연락처로 문의 주세요.
          </S.desc>
          <S.ASTell>
            <b>AS센터</b> 02-6925-5988
          </S.ASTell>
          <S.Tell>
            <b>대표전화</b> 02-6925-6080
          </S.Tell>
        </S.InfoArea>
      </S.LoginConainer>
    </S.Login>
  );
};

export default Login;
